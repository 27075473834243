<template>
  <div class="flex items-center">
    <el-avatar :size="44" :src="avatar" class="mr-2" style="background-color: #fff;margin-right: 10px;"></el-avatar>
    <el-dropdown trigger="click" @command="handleCommand">
      <span class="el-dropdown-link">
        {{ nickname }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="info">个人信息</el-dropdown-item>
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  computed: {
    nickname() {
      return this.$store.getters.nickname;
    },
    avatar() {
      return this.$store.getters.avatar;
    },
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "info":
          this.$router.push({ name: "userProfile" });
          break;
        case "logout":
          this.logoutHandle();
          break;
      }
    },
    logoutHandle() {
      this.$store.dispatch("user/Logout").then(() => {
        this.$router.replace({
          name: "Login",
        });
        this.$message.success("账号退出成功");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  font-size: 14rem;
}
</style>
